import React from "react";
import styles from "./Terms.module.scss";

export const Terms: React.FC = () => (
    <section className={styles.termsSection}>
        <b>Beta</b> features are subject to the Terms of Use. By using this Beta feature, you accept and agree to all of the
        {" "}
        <a
            href="https://www.themuse.com/user/terms"
            className={styles.termsLink}
        >
            terms and conditions
        </a>
        {" "}
        set forth in the Terms of Use. Your use of this Beta feature is voluntary and optional. If you do not
        agree to the Terms of Use, we ask you not to use this Beta feature. If you have questions about your
        personal information, please refer to our
        {" "}
        <a
            href="https://www.themuse.com/user/privacy"
            className={styles.termsLink}
        >
            Privacy Policy
        </a>
        {"."}
    </section>
);
