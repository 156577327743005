/* eslint-disable max-len */
import React from "react";
import styles from "./SideBar.module.scss";
import { PoweredBy } from "./PoweredBy";
import { Terms } from "./Terms";
import { ChatHistory } from "./ChatHistory";
import { SideBarProps } from "Maya/types";

export const SideBar: React.FC<SideBarProps> = ({ isOpen }) => (
    <aside className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}>
        <ChatHistory />
        <Terms />
        <PoweredBy />
    </aside>
);
