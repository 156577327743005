import React, { FC } from "react";
import { ArticleCard } from "./ArticleCard";
import styles from "./SourceArticleGrid.module.scss";
import { SourceArticle, SourceArticleGridProps } from "Maya/types";

// The main component with all tiles.
export const SourceArticleGrid: FC<SourceArticleGridProps> = ({ articles }) => (
    <aside className={styles.SourceArticles}>
        <h2 className={styles.SourceArticlesHeading}>
            Sources and related content
        </h2>
        {articles?.map((article: SourceArticle, index: number) => (
            <ArticleCard
                key={article.articleUri}
                article={article}
                index={index + 1}
            />
        )) ?? null}
    </aside>
);
