/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { FC } from "react";
import styles from "./Response.module.scss";
import { SourceArticleGrid } from "../Cards/SourceArticleGrid";
import { ResponsesProps } from "Maya/types";

/**
 * A functional component that renders a response section.
 *
 * @param {ResponsesProps} props - The properties object containing response data.
 * @param {Object} props.responses - The response data object.
 * @param {string} [props.responses.original_query=""] - The original query string.
 * @param {string} [props.responses.response_html=""] - The HTML formatted response.
 * @param {string} [props.responses.response_text=""] - The plain text response.
 * @param {Array} [props.responses.links=[]] - An array of link objects.
 *
 * @returns {JSX.Element} A section element displaying the original query, response content,
 * and a grid of unique source articles if available.
 */
export const Responses: FC<ResponsesProps> = ({ responses }) => {
    const { original_query = "", response_html = "", response_text = "", links = [] } = responses;
    const seen = [];
    const uniqueLinks = [];

    // dedupe the articles
    links.map((link: any) => {
        if (!seen.includes(link.articleId)) {
            uniqueLinks.push(link);
            seen.push(link.articleId);
        }
    });

    return (
        <section className={styles.response}>
            <div className={styles.originalQuery}>
                {original_query}
            </div>
            <div
                className={styles.content}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: response_html || response_text }}
            />
            {uniqueLinks?.length ? (<SourceArticleGrid {...{ articles: uniqueLinks }} />) : null}
        </section>
    );
};
