import React, { FC } from "react";
import getConfig from "next/config";
import { triggerSnowplowImpressionEvent, triggerSnowplowClickEvent } from "@themuse/snowplow-js-client";
import { getObserver } from "utils/observer";
import styles from "./ArticleCard.module.scss";
import { ArticleCardProps } from "Maya/types";

// Base tracking props common across all tiles.
const trackingProps = {
    // SnowplowClickEventProps
    sp_click_button_type: null,
    sp_click_component: "tile",
    sp_click_component_tile_type: "article",
    sp_click_insert_type: null,
    sp_click_search_id: null,
    sp_click_target_page_section: "advice",
    // SnowplowTileContentProps
    sp_tile_entity_uuid: "",
    sp_tile_location: "maya_Source_articles",
    sp_tile_type: "article",
    // SnowplowImpressionProps
    sp_impression_component: "tile",
    sp_impression_tile_type: "article",
    sp_impression_insert_type: null,
    sp_impression_modal_type: null,
    sp_impression_button_type: null,
    sp_impression_search_id: null,
    sp_impression_position: "main",
};

// Generated the tracking props for each tile.
const getTrackingProps = (id: number, title: string, index: number) => {
    const props = {
        ...trackingProps,
        // SnowplowClickEventProps
        sp_click_button_text: null,
        sp_click_link_text: title,
        sp_click_position: "main",
        // SnowplowTileContentProps
        sp_tile_company_id: "",
        sp_tile_entity_id: id.toString() ?? "",
        sp_tile_order: index,
    };
    return props;
};

// Generates an individual tile.
export const ArticleCard: FC<ArticleCardProps> = ({ article, index }) => {
    const {
        publicRuntimeConfig: { staticAssetHost }
    } = getConfig();
    const {
        title,
        articleUri,
        articleId,
        mediaUrl = `${staticAssetHost}/media/lead/placeholder.png`,
        writerName = ""
    } = article;
    const ref = React.useRef<HTMLElement>();

    const tileObserver = getObserver((element) => triggerSnowplowImpressionEvent(element));

    React.useEffect(() => {
        tileObserver.observe(ref.current);
        return () => {
            tileObserver.disconnect();
        };
    }, [tileObserver]);
    const tileTrackingProps = getTrackingProps(articleId, title, index);

    return (
        <article
            className={styles.container}
            ref={ref}
            data-tracking-props={JSON.stringify(tileTrackingProps)}
        >
            <div className={styles.cardBase}>
                <a href={articleUri} target="_blank" onClick={(e) => triggerSnowplowClickEvent({ ...e, ...tileTrackingProps })}>
                    <div className={styles.imageContainer}>
                        <img
                            loading="lazy"
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            src={`https://pilbox.themuse.com/image.jpg?filter=antialias&opt=1&mode=crop&pos=center&prog=1&q=keep&url=${mediaUrl}&w=244&`}
                            className={styles.coverImage}
                            alt=""
                        />
                    </div>
                    <div className={styles.contentContainer}>
                        <div className={styles.content}>
                            <span className={styles.author}>{writerName}</span>
                            <h2 className={styles.title}>{title}</h2>
                        </div>
                    </div>
                </a>
            </div>
        </article>
    );
};
