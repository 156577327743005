/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable max-len */
import React from "react";
import styles from "./PoweredBy.module.scss";

export const PoweredBy: React.FC = () => (
    <footer className={styles.poweredBySection}>
        <div className={styles.poweredByWrapper}>
            <span className={styles.poweredByText}>Powered by </span>
            <a href="/">
                <svg
                    className={styles.poweredByLogo}
                    xmlns="http://www.w3.org/2000/svg"
                    width="69"
                    height="15"
                    viewBox="0 0 69 15"
                    fill="none"
                >
                    <path d="M1.32195 11.1497V5.52258H0V5.24496C1.49733 4.6913 2.75378 3.63126 3.55165 2.24854H3.91739V4.89244H6.3586V5.56664H3.93943V10.9514C3.93943 11.8988 4.336 12.573 5.07189 12.573C5.49597 12.5517 5.89812 12.378 6.20437 12.0839L6.44673 12.1456C5.95761 13.1547 5.12477 14.0404 3.75435 14.0404C2.20766 13.9522 1.32195 12.9564 1.32195 11.1497Z" fill="#9ADBF5" />
                    <path d="M7.43815 11.9783V2.93609C7.43815 1.71108 6.76835 1.5877 6.15585 1.5877L6.125 1.31449L10.0115 0.671143V6.4613C10.2764 5.95062 10.6749 5.52143 11.1646 5.21954C11.6543 4.91765 12.2168 4.75438 12.7921 4.74717C13.1713 4.73161 13.5495 4.79588 13.9023 4.93582C14.2551 5.07576 14.5746 5.28824 14.8401 5.55951C15.1055 5.83077 15.311 6.15477 15.4433 6.51052C15.5755 6.86627 15.6316 7.24583 15.6078 7.62462V12.0003C15.6078 13.2209 16.2512 13.4368 16.8328 13.5294V13.8026H11.9857V13.5294C12.4748 13.4368 13.0256 13.1945 13.0256 12.0003V7.95952C13.0256 6.8006 12.3514 6.36877 11.6199 6.33792C11.0255 6.33353 10.4535 6.56486 10.0292 6.98127V11.9694C10.0292 13.1945 10.6108 13.406 11.1572 13.4985V13.7717H6.20431V13.5117C6.76835 13.4148 7.43815 13.2033 7.43815 11.9783Z" fill="#9ADBF5" />
                    <path d="M16.7402 9.34769C16.7402 6.47024 18.4544 4.7561 20.9617 4.7561C23.7158 4.7561 24.9275 6.89767 24.9275 9.13177H19.4194C19.5428 11.5201 20.7414 12.56 22.1118 12.56C22.6066 12.5427 23.0913 12.4151 23.5305 12.1863C23.9696 11.9576 24.3521 11.6336 24.6499 11.2381L24.8967 11.2998C24.6052 12.0782 24.0825 12.7488 23.3988 13.2214C22.715 13.694 21.903 13.9461 21.0718 13.9437C18.9435 13.9525 16.7402 12.4675 16.7402 9.34769ZM22.3718 8.58096C22.3718 7.20613 22.0016 5.33776 20.9308 5.33776C19.8601 5.33776 19.4326 7.32951 19.4018 8.58096H22.3718Z" fill="#9ADBF5" />
                    <path d="M39.4076 11.9782V7.93744C39.4293 7.7322 39.4058 7.52469 39.3388 7.32948C39.2718 7.13428 39.1629 6.9561 39.0197 6.80746C38.8765 6.65881 38.7025 6.5433 38.5099 6.46904C38.3174 6.39477 38.1109 6.36354 37.905 6.37754C37.3735 6.40049 36.8662 6.60591 36.4685 6.9592C36.5043 7.16154 36.5249 7.36628 36.5302 7.5717V11.9474C36.5302 13.1724 37.0501 13.3839 37.5701 13.5073V13.7805H33.0182V13.5117C33.5381 13.4192 34.0581 13.1768 34.0581 11.9518V7.94185C34.0581 6.81378 33.3839 6.38195 32.5555 6.38195C32.0446 6.38813 31.5544 6.58452 31.1806 6.93276V11.9518C31.1806 13.1768 31.7006 13.3883 32.2206 13.4808V13.7585H27.4175V13.4808C28.03 13.3883 28.6733 13.1459 28.6733 11.9518V6.62431C28.6733 5.39929 28.03 5.18778 27.4175 5.09524V4.81763H31.1806V6.46126C31.7931 5.45217 32.6789 4.69866 33.9656 4.69866C34.5351 4.6763 35.0946 4.85397 35.5469 5.20085C35.9992 5.54774 36.3159 6.04197 36.442 6.59786C37.0545 5.52708 37.9711 4.69866 39.2886 4.69866C40.756 4.69866 41.9325 5.68131 41.9325 7.57611V11.9518C41.9325 13.1768 42.6067 13.3883 43.1576 13.4808V13.7585H38.3853V13.4808C38.892 13.4147 39.4076 13.2032 39.4076 11.9782Z" fill="#9ADBF5" />
                    <path d="M43.2632 11.0309V6.68606C43.2632 5.46105 42.6242 5.24513 42.0117 5.157V4.87939H45.8366V10.6916C45.8366 11.8549 46.5064 12.2823 47.2422 12.3132C47.5437 12.3137 47.8423 12.2543 48.1206 12.1386C48.399 12.0229 48.6516 11.8531 48.8638 11.639V6.68606C48.8638 5.46105 48.313 5.24513 47.8239 5.157V4.87939H51.4328V12.0091C51.4328 13.2297 52.107 13.4457 52.6579 13.569V13.8422H49.5733C49.2464 13.3802 49.0074 12.8618 48.8683 12.3132C48.5655 12.8007 48.1467 13.2057 47.6491 13.4917C47.1516 13.7778 46.5909 13.9361 46.0172 13.9524C44.5499 13.9039 43.2632 12.9257 43.2632 11.0309Z" fill="#9ADBF5" />
                    <path d="M52.962 13.2298L52.9928 10.63H53.3013C54.3412 12.7099 55.3195 13.3532 56.3594 13.3532C57.2407 13.3532 57.6814 12.8641 57.6814 12.2207C57.6814 11.15 56.4608 10.8459 55.1124 10.2642C53.5216 9.55921 52.7593 8.76603 52.7593 7.54102C52.7593 5.91942 54.0416 4.7561 56.2448 4.7561C57.2657 4.78818 58.2722 5.0062 59.2148 5.39945V7.81863H58.9064C58.0824 5.7652 57.1041 5.36861 56.2625 5.36861C55.3151 5.36861 54.7642 5.85773 54.7642 6.50108C54.7642 7.41764 56.051 7.69525 57.699 8.51927C58.6816 8.95992 59.7216 9.55921 59.7216 11.1191C59.7216 12.9566 58.3115 14.0274 56.3242 14.0274C55.1672 13.9485 54.0312 13.679 52.962 13.2298Z" fill="#9ADBF5" />
                    <path d="M60.5586 9.34769C60.5586 6.47024 62.2727 4.7561 64.78 4.7561C67.5341 4.7561 68.7459 6.89767 68.7459 9.13177H63.2686C63.392 11.5201 64.5906 12.56 65.961 12.56C66.4597 12.5402 66.948 12.4117 67.3917 12.1833C67.8355 11.955 68.224 11.6324 68.53 11.2381L68.7767 11.2998C68.4853 12.0782 67.9626 12.7488 67.2788 13.2214C66.5951 13.694 65.7831 13.9461 64.9519 13.9437C62.7222 13.9525 60.5586 12.4675 60.5586 9.34769ZM66.1593 8.58096C66.1593 7.20613 65.7891 5.33776 64.7492 5.33776C63.7093 5.33776 63.251 7.32951 63.2201 8.58096H66.1593Z" fill="#9ADBF5" />
                </svg>
            </a>
        </div>
    </footer>
);
