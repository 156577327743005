import React from "react";
import styles from "./ChatHistory.module.scss";

export const ChatHistory: React.FC = () => (
    <div className={styles.chatHistory}>
        <div className={styles.chatHistoryContent} />
        <p className={styles.emptyStateText}>
            Maya can provide AI powered career advice, guidance, and support—all backed by the expert insights
            The Muse is known for. From interview prep to 401k vesting, Maya has all the answers.
        </p>
    </div>
);
