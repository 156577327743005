/* eslint-disable max-len */
import React from "react";
import styles from "./NewChatButton.module.scss";
import { NewChatButtonProps } from "Maya/types";

export const NewChatButton: React.FC<NewChatButtonProps> = ({ onClick }) => (
    <button
        type="button"
        className={styles.newChatButton}
        onClick={onClick}
        aria-label="Start new chat"
    >
        <span className={styles.newChatText}>New Chat</span>
        <svg
            className={styles.newChatIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
        >
            <path d="M9.02316 10.145L6.49316 10.507L6.85516 7.97702L13.3605 1.47168C13.5018 1.32448 13.671 1.20694 13.8582 1.12596C14.0455 1.04498 14.247 1.00218 14.451 1.00008C14.6551 0.997978 14.8574 1.03661 15.0463 1.11371C15.2352 1.19082 15.4068 1.30484 15.5511 1.4491C15.6953 1.59337 15.8094 1.76497 15.8865 1.95386C15.9636 2.14275 16.0022 2.34513 16.0001 2.54914C15.998 2.75315 15.9552 2.95469 15.8742 3.14195C15.7932 3.32921 15.6757 3.49842 15.5285 3.63968L9.02316 10.145Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.59963 4.35547H1.97786C1.62424 4.35547 1.2851 4.49594 1.03506 4.74599C0.785007 4.99604 0.644531 5.33518 0.644531 5.6888V15.0221C0.644531 15.3758 0.785007 15.7149 1.03506 15.9649C1.2851 16.215 1.62424 16.3555 1.97786 16.3555H11.3112C11.6648 16.3555 12.004 16.215 12.254 15.9649C12.5041 15.7149 12.6445 15.3758 12.6445 15.0221V10.147" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </button>
);
