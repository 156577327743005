/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import styles from "./Header.module.scss";
import { NewChatButton } from "./NewChatButton";
import { MuseLogo } from "./MuseLogo";
import { Toggle } from "./Toggle";
import { HeaderProps } from "Maya/types";

export const Header: React.FC<HeaderProps> = ({ handleToggleSidebar, handleNewChat }) => (
    <header className={styles.header}>
        <Toggle {...{ handleToggleSidebar }} />
        <a href="https://www.themuse.com"><MuseLogo /></a>
        <NewChatButton onClick={handleNewChat} />
    </header>
);
