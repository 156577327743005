/* eslint-disable max-len */
import React from "react";
import styles from "./Toggle.module.scss";
import { ToggleProps } from "Maya/types";

export const Toggle: React.FC<ToggleProps> = ({ handleToggleSidebar }) => (
    <svg
        className={styles.toggle}
        onClick={handleToggleSidebar}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
    >
        <path d="M9.5293 19.3279L16.0796 12.7776C16.1387 12.7186 16.1856 12.6485 16.2176 12.5713C16.2496 12.4941 16.2661 12.4114 16.2661 12.3279C16.2661 12.2443 16.2496 12.1616 16.2176 12.0845C16.1856 12.0073 16.1387 11.9372 16.0796 11.8782L9.5293 5.32788" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
