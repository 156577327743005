import { formatLog, useSessionStorage } from "@themuse/design-system";
import { getActiveVariant } from "@themuse/experiment-client";
import {
    initSnowplowTracker,
    trackSnowplowClicks,
    trackSnowplowImpressions,
    trackSnowplowModalActions,
    triggerSnowplowPageViewEvent
} from "@themuse/snowplow-js-client";
import { getActiveFeatureFlags } from "utils/featureFlags";
import { FeatureFlagsProvider } from "hooks/FeatureFlagsContext";
import { MayaContent } from "Maya/MayaContent";
import { AppProvider, useAppDispatch, useAppState } from "hooks/AppContext";
import { PageMetaDataActionTypes, UserActionTypes } from "interfaces/context/appContextInterfaces";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import getConfig from "next/config";
import Head from "next/head";
import Script from "next/script";
import {
    FC, PropsWithChildren, useEffect, useMemo, useState
} from "react";
import { fetchApiCarmenData, fetchUser } from "utils/api";
import { getFetchError } from "utils/errors";

const setUserCarmenData = async (setUserLocation: any) => {
    try {
        const carmenResponse = await fetchApiCarmenData();
        if (carmenResponse) {
            setUserLocation(JSON.stringify(carmenResponse));
        }
    } catch (error) {
        const msg = getFetchError("Carmen Location", "Front-End");
        console.error(msg, JSON.stringify(formatLog({ error })));
    }
};

const setUser = async (userLocation, dispatch) => {
    try {
        const apiUser = await fetchUser();
        const userData = userLocation ? {
            location: JSON.parse(userLocation)
        } : {};
        dispatch({
            type: UserActionTypes.updateUser,
            payload: {
                ...apiUser,
                location: userData,
                isInitialized: true,
            }
        });
    } catch (error) {
        dispatch({
            type: UserActionTypes.updateUser,
            logged_in: false,
            view_bookings: false,
            location: null,
            isInitialized: true,
        });
        const msg = getFetchError("User", "Front-End");
        console.error(msg, JSON.stringify(formatLog({ error })));
    }
};

const UserInit: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { user } = useAppState();
    const [userLocation, setUserLocation] = useSessionStorage("user_location");
    const appDispatch = useAppDispatch();

    useEffect(() => {
        setUser(userLocation, appDispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation]);

    useEffect(() => {
        setUserCarmenData(setUserLocation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        appDispatch({
            type: UserActionTypes.updateUser,
            payload: {
                ...user,
                location: userLocation ? JSON.parse(userLocation) : user?.location
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation]);

    return <>{ children }</>;
};

const PageMetaInit: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { pageMetaData } = useAppState();
    const appDispatch = useAppDispatch();

    useEffect(() => {
        const { href, search } = window.location;
        const { referrer } = document;
        appDispatch({
            type: PageMetaDataActionTypes.updatePageMetaData,
            payload: {
                ...pageMetaData,
                ...{
                    href,
                    search,
                    referrer
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{ children }</>;
};

const SnowPlowInit = () => {
    const { publicRuntimeConfig: { snowplowCollector } } = getConfig();
    const { user } = useAppState();
    const [pageViewed, setPageViewed] = useState<boolean>(false);

    const pageData = useMemo(() => ({
        sp_page_is_external: false,
        sp_page_section: "maya",
        sp_page_sponsor_id: null,
        sp_page_tab: null,
        sp_page_type: "other",
        sp_user_logged_in: user?.logged_in ?? false,
        sp_user_id: user?.id ?? 0,
        sp_experiment_experiment_id: "",
        sp_experiment_variant_id: ""
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    useEffect(() => {
        if (!pageViewed) {
            // eslint-disable-next-line no-underscore-dangle
            const enabledVariant = getActiveVariant("", window.__NEXT_DATA__.props?.pageProps?.experiments ?? []);
            const experimentData = enabledVariant
                ? {
                    sp_experiment_experiment_id: "",
                    sp_experiment_variant_id: enabledVariant
                }
                : {};

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                ...pageData,
                ...experimentData
            });
            initSnowplowTracker(snowplowCollector);
            trackSnowplowImpressions();
            trackSnowplowClicks();
            trackSnowplowModalActions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user.isInitialized && !pageViewed) {
            triggerSnowplowPageViewEvent();
            setPageViewed(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return null;
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    return {
        props: {
            featureFlags: {
                ...getActiveFeatureFlags(context),
            }
        }
    };
};

const MayaPage = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    const { featureFlags } = props;
    return (
        <>
            <Head>
            <title>Maya—AI-powered career advice from The Muse</title>
                <link rel="canonical" href="https://www.themuse.com/maya" />
                <link
                    rel="preload"
                    as="font"
                    type="font/woff2"
                    href="https://public-assets.themuse.com/fonts/nunito-sans-v6-latin-regular.woff2"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    type="font/woff2"
                    href="https://public-assets.themuse.com/fonts/nunito-sans-v6-latin-600.woff2"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    type="font/woff2"
                    href="https://public-assets.themuse.com/fonts/barlow-semi-condensed-v6-latin-600.woff2"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    type="font/woff2"
                    href="https://public-assets.themuse.com/fonts/barlow-semi-condensed-v6-latin-regular.woff2"
                    crossOrigin="anonymous"
                />
                <style>{"body { background-color: #fff !important }"}</style>
                <meta key="robots" name="robots" content="index,follow" />
                <meta key="description" name="description" content="From interview prep to 401k vesting, Maya has all the answers" />
                <meta key="referrer" name="referrer" content="no-referrer-when-downgrade" />
                <meta key="og:locale" content="en_US" property="og:locale" />
                <meta key="og:site_name" content="The Muse" property="og:site_name" />
                <meta key="og:type" content="website" property="og:type" />
                <meta key="og:url" content="https://www.themuse.com/maya" property="og:url" />
                <meta key="og:title" content="Maya—AI-powered career advice from The Muse" property="og:title" />
                <meta key="og:description" content="From interview prep to 401k vesting, Maya has all the answers" property="og:description" />
                <meta key="og:image" content="https://www.themuse.com/static/images/muse-og-image.png" property="og:image" />
                <meta key="og:image:width" content="504" property="og:image:width" />
                <meta key="og:image:height" content="385" property="og:image:height" />
                <meta key="og:image:alt" content="The Muse" property="og:image:alt" />
                <meta key="og:see_also1" content="http://github.com/dailymuse/" property="og:see_also" />
                <meta key="og:see_also2" content="https://pinterest.com/thedailymuse/" property="og:see_also" />
                <meta key="og:see_also3" content="https://instagram.com/themuse/" property="og:see_also" />
                <meta key="og:see_also4" content="https://www.youtube.com/channel/UCk4bbQAZD26f_XdGyb4wwhg" property="og:see_also" />
                <meta key="og:see_also5" content="https://www.linkedin.com/company/the-daily-muse" property="og:see_also" />
                <meta key="og:see_also6" content="https://en.wikipedia.org/wiki/The_Muse_(website)" property="og:see_also" />
                <meta key="og:see_also7" content="https://www.facebook.com/thedailymuse" property="og:see_also" />
                <meta key="og:see_also8" content="https://twitter.com/themuse" property="og:see_also" />
                <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
                <meta key="twitter:site" name="twitter:site" content="@TheMuse" />
                <meta key="twitter:creator" name="twitter:creator" content="@TheMuse" />
                <meta key="twitter:title" name="twitter:title" content="Maya—AI-powered career advice from The Muse" />
                <meta key="twitter:description" name="twitter:description" content="From interview prep to 401k vesting, Maya has all the answers" />
                <meta key="twitter:image" name="twitter:image" content="https://www.themuse.com/static/images/muse-og-image.png" />
                <meta key="twitter:image:width" name="twitter:image:width" content="504" />
                <meta key="witter:image:height" name="twitter:image:height" content="385" />
                <meta key="twitter:image:alt" name="twitter:image:alt" content="The Muse" />
                <meta key="msvalidate.01" name="msvalidate.01" content="4115DAD7B842017BA77853D5654B5CB0" />
                <meta key="p:domain_verify" name="p:domain_verify" content="09380361cb040eec0860a103a9c11da2" />
                <meta key="og:image:alt" property="og:image:alt" content="The Muse" />
                <link key="icon180x180" rel="apple-touch-icon" sizes="180x180" href="https://public-assets.themuse.com/images/favicons/apple-touch-icon.png" />
                <link key="icon32x32" rel="icon" type="image/png" sizes="32x32" href="https://public-assets.themuse.com/images/favicons/favicon-32x32.png" />
                <link key="icon16x16" rel="icon" type="image/png" sizes="16x16" href="https://public-assets.themuse.com/images/favicons/favicon-16x16.png" />
                <link key="manifest" rel="manifest" href="https://public-assets.themuse.com/images/favicons/site.webmanifest" />
                <link key="mask-icon" rel="mask-icon" href="https://public-assets.themuse.com/images/favicons/safari-pinned-tab.svg" color="#9ADBF5" />
                <link key="iconShortcut" rel="shortcut icon" href="https://public-assets.themuse.com/images/favicons/favicon.ico" />
                <meta key="apple-mobile-web-app-title" name="apple-mobile-web-app-title" content="The Muse" />
                <meta key="application-name" name="application-name" content="The Muse" />
                <meta key="msapplication-TileColor" name="msapplication-TileColor" content="#9ADBF5" />
                <meta key="msapplication-config" name="msapplication-config" content="https://public-assets.themuse.com/images/favicons/browserconfig.xml" />
                <meta key="theme-color" name="theme-color" content="#9ADBF5" />
            </Head>
            <Script
                id="Organization-JSON"
                type="application/ld+json"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        name: "The Muse",
                        url: "https://www.themuse.com/",
                        logo: "https://www.themuse.com/static/images/muse-amp-logo.jpg?v=212881e6fc388b7a61e11bb9d8ab8d0a879e4dc1a4f5ac328c41df8b0f6ee5ab",
                        sameAs: [
                            "https://www.facebook.com/thedailymuse",
                            "https://twitter.com/TheMuse",
                            "https://www.instagram.com/themuse/",
                            "https://www.youtube.com/channel/UCk4bbQAZD26f_XdGyb4wwhg",
                            "https://www.linkedin.com/company/the-daily-muse/",
                            "https://en.wikipedia.org/wiki/The_Muse_(website)",
                            "https://www.pinterest.com/thedailymuse/_created/"
                        ]
                    })
                }}
            />
            <Script
                id="WebSite-JSON"
                type="application/ld+json"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        name: "The Muse",
                        url: "https://www.themuse.com/",
                        potentialAction: {
                            "@type": "SearchAction",
                            target: "https://www.themuse.com/search?keyword={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    })
                }}
            />
            <AppProvider {...{}}>
                <FeatureFlagsProvider featureFlags={featureFlags}>
                    <UserInit>
                        <PageMetaInit>
                            <SnowPlowInit />
                            <MayaContent />
                        </PageMetaInit>
                    </UserInit>
                </FeatureFlagsProvider>
            </AppProvider>
        </>
    );
};

MayaPage.displayName = "MayaPage";

export default MayaPage;
