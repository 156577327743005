import React, { FC } from "react";
import styles from "./QuestionCardGrid.module.scss";
import { QuestionCard } from "./QuestionCard";
import { QuestionsGridProps } from "Maya/types";
import questions from "./questions.json";

/**
 * Renders a grid of question cards within a section element.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleSubmit - The function to handle form submission.
 * @param {React.RefObject} props.inputRef - A reference to the input element.
 *
 * @returns {JSX.Element} A section containing a grid of question cards.
 */
export const QuestionsGrid: FC<QuestionsGridProps> = ({ handleSubmit, inputRef }) => (
    <section className={styles.container}>
        <div className={styles.row}>
            <div className={styles.cardWrapper}>
                <QuestionCard {...{ ...questions[0], handleSubmit, inputRef } } />
            </div>
            <div className={styles.cardWrapper}>
                <QuestionCard {...{ ...questions[1], handleSubmit, inputRef } } />
            </div>
        </div>
        <div className={styles.row}>
            <div className={styles.cardWrapper}>
                <QuestionCard {...{ ...questions[2], handleSubmit, inputRef } } />
            </div>
            <div className={styles.cardWrapper}>
                <QuestionCard {...{ ...questions[3], handleSubmit, inputRef } } />
            </div>
        </div>
    </section>
);
