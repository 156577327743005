/* eslint-disable max-len */
import React from "react";
import styles from "./MuseLogo.module.scss";

export const MuseLogo: React.FC = () => (
    <svg className={styles.logo} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 39 33">
        <g clipPath="url(#a)">
            <path fill="#9ADBF5" d="m28.741 6.223 1.542-.464a3.348 3.348 0 0 0 2.236-2.236l.464-1.541c.093-.309.533-.309.625 0l.464 1.541a3.348 3.348 0 0 0 2.236 2.236l1.542.464c.308.093.308.533 0 .625l-1.542.464a3.348 3.348 0 0 0-2.236 2.236l-.464 1.542c-.092.308-.532.308-.625 0l-.464-1.542a3.348 3.348 0 0 0-2.236-2.236l-1.542-.464c-.308-.092-.308-.532 0-.625ZM23.368 3.46l.784-.237a2.333 2.333 0 0 0 1.56-1.559l.236-.784c.08-.27.46-.27.545 0l.236.784a2.333 2.333 0 0 0 1.56 1.56l.784.236c.27.08.27.46 0 .545l-.785.236a2.333 2.333 0 0 0-1.559 1.56l-.236.784c-.081.27-.461.27-.545 0L25.71 5.8a2.333 2.333 0 0 0-1.56-1.56l-.783-.236c-.27-.081-.27-.461 0-.545Zm-8.065 21.802v-.23A14.464 14.464 0 0 0 2.14 10.616a1.21 1.21 0 0 0-1.323 1.079v19.778a1.234 1.234 0 0 0 1.22 1.217h13.265v-7.428Z" />
            <path fill="#EAF6FA" d="M15.303 25.262v-.23a14.464 14.464 0 0 1 13.16-14.416 1.222 1.222 0 0 1 1.324 1.153v19.743a1.231 1.231 0 0 1-1.217 1.217H15.302v-7.467Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.818.678H38.08v32H.818z" />
            </clipPath>
        </defs>
    </svg>
);
