import React, { useState, useRef, FormEvent } from "react";
import getConfig from "next/config";

import { Header } from "./Header/Header";
import { Intro } from "./Content/Intro";
import { Input } from "./Content/Input";
import { SideBar } from "./SideBar/SideBar";
import { Responses } from "./Content/Response";
import { Loading } from "./Content/Loading";
import { QuestionsGrid } from "./Cards/QuestionCardGrid";
import { getMayaResponse } from "utils/api";

import styles from "./MayaContent.module.scss";
import { useFeatureFlagsState } from "hooks/FeatureFlagsContext";

/**
 * MayaContent is a React functional component that renders the main interface
 * for interacting with the Maya chatbot. It manages the state for the sidebar
 * visibility, loading status, and chatbot responses. The component includes
 * handlers for starting a new chat session, toggling the sidebar, and submitting
 * queries to the Maya API. It also coordinates the display of various UI elements
 * such as the header, sidebar, loading indicator, and response content.
 */
export const MayaContent: React.FC = () => {
    const { publicRuntimeConfig: { mayaApi } } = getConfig();
    const { FEATURE_FLAG_MAYA_SUGGESTIONS } = useFeatureFlagsState();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responses, setResponses] = useState<Record<string, any>>();
    const inputRef = useRef<HTMLInputElement>(null);

    /**
     * Clears the current input value and resets the responses state.
     * This function is typically used to initialize a new chat session.
     */
    const handleNewChat = () => {
        inputRef.current.value = "";
        setResponses(null);
    };

    /**
     * Toggles the state of the sidebar between open and closed.
     */
    const handleToggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    /**
     * Handles the form submission event by preventing the default behavior,
     * blurring the active element, and scrolling to the top of the page.
     * Initiates an API call to fetch a response based on the input query,
     * updates the responses state with the result, and resets the input field.
     * Manages the loading state throughout the process.
     *
     * @param event - The form submission event.
     */
    const handleSubmit = async (event: FormEvent<HTMLFormElement | null>) => {
        event?.preventDefault?.();
        (document.activeElement as HTMLElement)?.blur();
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsLoading(true);
        const query = inputRef.current?.value || "";
        const result = await getMayaResponse({ mayaApi, query });
        setResponses(result);
        inputRef.current.value = "";
        setIsLoading(false);
    };

    return (
        <main className={styles.chatbotPanel}>
            {!responses ? (<span className={styles.gradientYellow} />) : null}
            <Header {...{ handleToggleSidebar, handleNewChat }} />
            <SideBar {...{ isOpen }} />
            <div className={styles.mainContent}>
                <section className={styles.conversationWrapper}>
                    <div className={styles.responseCanvas}>
                        {isLoading ? (<Loading />) : null}
                        {!isLoading && responses ? (<Responses {...{ responses }} />) : null}
                        {!isLoading && !responses ? (<Intro />) : null}
                        {FEATURE_FLAG_MAYA_SUGGESTIONS && !isLoading && !responses ? (
                            <QuestionsGrid {...{ handleSubmit, inputRef }} />
                        ) : null}
                    </div>
                    <Input {...{ handleSubmit, inputRef, hasResults: !!responses?.response_text }} />
                </section>
            </div>
        </main>
    );
};
